// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-slideshow-container button {
    display: none;
}

.react-slideshow-fadezoom-images-wrap > div {
    position: absolute;
    top: 0;
    left: auto !important;
}

div.each-fade {
    box-sizing: border-box;
    min-width: 100%;
    overflow: hidden;
    object-fit: cover;
    transform: translateX(0);
    transition: transform 1s ease;
    height: 33vh;

    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;
    background-attachment: fixed;
}
/*-----------------*/
`, "",{"version":3,"sources":["webpack://./src/css/slider.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;IACxB,6BAA6B;IAC7B,YAAY;;IAEZ,4BAA4B;IAC5B,0BAA0B;IAC1B,kCAAkC;IAClC,4BAA4B;AAChC;AACA,oBAAoB","sourcesContent":[".react-slideshow-container button {\n    display: none;\n}\n\n.react-slideshow-fadezoom-images-wrap > div {\n    position: absolute;\n    top: 0;\n    left: auto !important;\n}\n\ndiv.each-fade {\n    box-sizing: border-box;\n    min-width: 100%;\n    overflow: hidden;\n    object-fit: cover;\n    transform: translateX(0);\n    transition: transform 1s ease;\n    height: 33vh;\n\n    background-repeat: no-repeat;\n    background-size: 100% auto;\n    background-position: center center;\n    background-attachment: fixed;\n}\n/*-----------------*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
