import _ from "lodash"
import React, {useEffect, useState} from 'react';

import {Addresses, Ads, Files, Main} from '../services/api'
import DrinkCard from "./drink-card";
import Drink from "./drink";
import Slider from "./slider";
import {Image} from "semantic-ui-react";

import imgGlass from "../images/glass.png";

const Menu = (props) => {
    const {address} = props
    const [title, setTitle] = useState([''])
    const [ads, setAds] = useState([{}])
    const [drinks, setDrinks] = useState([])
    const [drink, setDrink] = useState()

    let count = 0;
    let elements = new Map();

    useEffect(() => {
        let time;
        const btnClick = (event) => {
            let countdown;
            function reset() {
                count = 0;
                countdown = null;
            }

            count++;

            if (count === 3) {
                if (!elements.has(event.target)) {
                    elements.set(event.target, 1);
                } else {
                    let currentCount = elements.get(event.target);
                    currentCount++;
                    elements.set(event.target, currentCount);
                }

                let tripleClick = new CustomEvent('trplclick', {
                    bubbles: true,
                    detail: {
                        numberOfTripleClicks: elements.get(event.target)
                    }
                });

                event.target.dispatchEvent(tripleClick);
                reset();
            }

            if (!countdown) {
                countdown = window.setTimeout(function () {
                    reset();
                }, 500);
            }
        }

        document.getElementById('menu_title').addEventListener('click', btnClick);
        document.getElementById('menu_title').addEventListener('touchend', btnClick);

        const trplClick = function (event) {
            const exit = window.confirm('Выйти?');
            if (exit) props.logout();
        }

        document.addEventListener('trplclick', trplClick);

        window.addEventListener('load', resetTimer, true);
        var events = ['click', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach(function (name) {
            document.addEventListener(name, resetTimer, true);
        });

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(close, 30 * 1000)
        }
        const fetch = async () => {
            const adss = await Ads.list();
            const ads = await Promise.all(_.map(adss.data, async ({
                                                                      attributes: {
                                                                          img: {
                                                                              data: {
                                                                                  attributes: {
                                                                                      name,
                                                                                      url
                                                                                  }
                                                                              }
                                                                          }
                                                                      }
                                                                  }) => {
                return {src: await Files.blob(url), alt: name};
            }));
            setAds(ads);

            const main = await Main.get();

            setTitle(main.data.attributes.title)

            const list = await Addresses.drinks(address);

            _.each(list.data, (drink, indx) => {
                if (list.data[indx - 1]) list.data[indx].prev = list.data[indx - 1];
                if (list.data[indx + 1]) list.data[indx].next = list.data[indx + 1];
            })
            setDrinks(list.data);

            return () => {

            };
        };

        fetch();

        if (document.body.offsetHeight < window.innerHeight) {
            document.getElementById("menu_bottom").style.visibility = 'hidden';
        }

        const checkScroll = () => {
            const jScroll = document.getElementById("menu_bottom");

            if (jScroll) {
                if (window.scrollY + window.innerHeight < window.document.body.scrollHeight - 100) {
                    document.getElementById("menu_bottom").style.visibility = 'visible';
                } else {
                    document.getElementById("menu_bottom").style.visibility = 'hidden';
                }
            }
        }
        window.addEventListener("scroll", checkScroll)

        return () => {
            const menuTitle = document.getElementById('menu_title');
            document.removeEventListener('trplclick', trplClick);
            if (menuTitle) menuTitle.removeEventListener('click', btnClick);
            if (menuTitle) menuTitle.removeEventListener('touchend', btnClick);

            window.removeEventListener('load', resetTimer);

            events.forEach(function (name) {
                document.removeEventListener(name, resetTimer);
            });

            window.removeEventListener("scroll", checkScroll)
        }
    }, [address]);

    const close = () => {
        window.scrollTo(0, 0);
        setDrink(null);
    }

    return (<div className={"black jb"}>{!drink ?
            <div id={"main"}>
                <div id={"ads"}>
                    <Slider images={ads}/>
                </div>
                <div id={"menu"}>

                    <div id={"menu_title"}>{title}<Image src={imgGlass} id={"menu_title_glass_img"} /><span id={"menu_title_glass"}>50мл</span></div>
                    <div id={"menu_list"}>
                        {_.map(drinks, drink => <DrinkCard key={`drink.card.${drink.id}`} select={() => setDrink(drink)}
                                                           drink={drink}/>)}
                    </div>
                    {window ?<div id={"menu_bottom"}><div className={"scroll"} /></div> : <></>}
                </div>
            </div> :
            <Drink close={close} prev={drink.prev ? () => setDrink(drink.prev) : null}
                   next={drink.next ? () => setDrink(drink.next) : null} drink={drink}/>
        }</div>
    );
}

export default Menu
