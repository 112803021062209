import React, {useEffect, useState} from 'react'
import {
    Image,
} from 'semantic-ui-react'
import { Files} from "../services/api";

const DrinkCard = ({ drink, select }) => {
    const [img, setImg] = useState();
    useEffect(() => {
        const fetch = async () => {
            const imgBlob = drink.attributes.img ? await Files.blob(drink.attributes.img.data.attributes.url) : '';

            setImg(imgBlob);
        };

        fetch();
    }, []);

    return (
        <div id={"drink"} onClick={select}>
            <div id={"drink_img"}>{drink.attributes.img ? <Image style={{visibility: img ? 'visible' : 'hidden' }} src={img} alt={drink.attributes.name}/> : <></>}</div>
            <div id={"drink_title"}>
                {drink.attributes.name}
                {drink.attributes.new ? <Image id={"drink_new"} src="/new.png" /> : <></>}
                {drink.attributes.hit ? <Image id={"drink_hit"} src="/hit.png" /> : <></>}
            </div>
            <div id={"drink_price"}>{drink.attributes.price ? `${drink.attributes.price}р` : <></>}</div>
        </div>
    )
}

export default DrinkCard
