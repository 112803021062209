import _ from 'lodash'
import React, {useState} from 'react'
import Menu from "./menu"
import AddressesList from "./addresses";

const Index = (props) => {
    const [address, setAddress] = useState(localStorage.getItem('address'));

    return (
        <>
            {_.isNil(address) ? <AddressesList setAddress={setAddress} /> : <Menu logout={props.logout} address={address} />}
        </>
    );
}

export default Index;
