import React, {useState} from 'react'
import {Button, Form, Grid, Header, Image, Segment} from 'semantic-ui-react'
import {Authorization} from '../services/api'

const LoginForm = (props) => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    const auth = async () => {
        const response = await Authorization.auth({
            login,
            password
        });

        localStorage.setItem('jwt', response.jwt);

        props.login();

        return response.jwt;
    }

    const onLoginChange = (event, data) => {
        setLogin(data.value);
    }

    const onPasswordChange = (event, data) => {
        setPassword(data.value);
    }


    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='teal' textAlign='center'>
                    <Image src='/logo.png'/> Авторизация
                </Header>
                <Form size='large'>
                    <Segment stacked>
                        <Form.Input onChange={onLoginChange} value={login} fluid icon='user' iconPosition='left' placeholder='Логин'/>
                        <Form.Input
                            onChange={onPasswordChange}
                            value={password}
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Пароль'
                            type='password'
                        />

                        <Button onClick={auth} color='teal' fluid size='large'>
                            Войти
                        </Button>
                    </Segment>
                </Form>
            </Grid.Column>
        </Grid>
    );
}

export default LoginForm
