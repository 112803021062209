import axios from 'axios';
import md5 from 'md5';

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

const instanceAxios = axios.create();

const cache = {};

instanceAxios.interceptors.request.use(
    config => {
        const token = window.localStorage.getItem('jwt');

        if (token) config.headers['Authorization'] = `Bearer ${token}`;

        return config;
    },
    err => {
        console.error(err);
        return Promise.reject(err);
    }
);

instanceAxios.interceptors.response.use(undefined, err => {
    if (err.message === 'Network Error' && !err.response) {
        console.error(err);
    }

    const {status, data/*, config*/} = err.response || {};

    if (status === 401) {
        window.localStorage.removeItem('jwt');
        window.location.href = '/';

        return Promise.reject(err);
    }

    if (
        status === 400 /*&&
		config.method === 'get' &&
		data.errors.hasOwnProperty('id')*/
    ) {
        console.error(data.errors);
    }

    if (status === 500) {
        console.error(data.errors);
    }

    throw err.response;
});

const responseBody = ({data}) => data;

const requestsCache = {
    blob: async (url /* <String> */, params /* Object */, headers /* Object */) => {
        const key = `cache.${md5(url)}`;
        const data = cache[key];

        if (data) {
            return data;
        } else {
            const response = await instanceAxios
                .get(url, {responseType: "arraybuffer", params, headers}).then(responseBody);

            const blob = new Blob([response]);

            const file = new File([blob], 'drink.png', {lastModified: new Date().getTime(), type: blob.type});
            const fileReader = new FileReader();

            await new Promise((resolve, reject) => {
                fileReader.onload = resolve;
                fileReader.onerror = reject;
                fileReader.readAsDataURL(file);
            });
            const src = fileReader.result;
            cache[key] = src;
            return src;
        }
    },
};

const requests = {
    get: (url /* <String> */, params /* Object */, headers /* Object */) =>
        instanceAxios
            .get(url, {params, headers})
            .then(responseBody),
    post: (url /* <String> */, body /* <JSON> */) =>
        instanceAxios
            .post(url, body)
            .then(responseBody),
};

export const Ads = {
    list: () =>
        requests.get('/adss?sort[0]=order:asc&populate[0]=img', {}),
}

export const Addresses = {
    list: () =>
        requests.get('/addresses', {}),
    drinks: (address) =>
        requests.get(`/addresses/${address}?populate[drinks][populate]=*`, {}).then(({data}) => data.attributes.drinks),
}

export const Drinks = {
    list: (address) =>
        requests.get(`/drinks?populate=*&filters[addresses]=${address}`, {}),
    item: (id) =>
        requests.get(`/drinks/${id}`, {}),
}

export const Main = {
    get: () =>
        requests.get(`/main`, {}),
}

export const Files = {
    blob: (hash) =>
        requestsCache.blob(`..${hash}`, {}),
}

export const Authorization = {
    auth: ({login, password} = {}) =>
        axios.post('/auth/local', {identifier: login, password}).then(responseBody),
}

export default {
    Authorization,

    Addresses,
    Drinks,
    Ads,

    Main,

    Files
};
