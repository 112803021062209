import _ from "lodash"
import React, {useEffect, useState} from 'react'
import {Dropdown, Form, Grid, Header, Image} from 'semantic-ui-react'
import {Addresses} from '../services/api'

const AddressesList = (props) => {
    const [addresses, setAddresses] = useState([])

    useEffect(() => {
        const fetch = async () => {
            const list = await Addresses.list();

            const addresses = _.map(list.data, address => ({
                key: `address.${address.id}`,
                text: address.attributes.title,
                value: address.id,
            }));

            setAddresses(addresses);
        };

        fetch();
    }, [])

    const onAddressChange = (event, data) => {
        localStorage.setItem('address', data.value);
        props.setAddress(data.value);
    }

    return (
        <Grid textAlign='center' style={{height: '100vh'}} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
                <Header as='h2' color='teal' textAlign='center'>
                    <Image src='/logo.png'/> Выберите адрес
                </Header>
                <Form size='large'>
                    <Dropdown
                        placeholder='Адрес'
                        fluid
                        selection
                        options={addresses}
                        onChange={onAddressChange}
                    />
                </Form>
            </Grid.Column>
        </Grid>
    );
}

export default AddressesList
