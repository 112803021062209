import React, {useEffect} from 'react'
import {
    Icon,
} from 'semantic-ui-react'
import {Files} from "../services/api";

const Drink = ({drink, next, prev, close}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
        var time;

        window.addEventListener('load', resetTimer, true);
        var events = ['click', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
        events.forEach(function (name) {
            document.addEventListener(name, resetTimer, true);
        });

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(close, 2 * 60 * 1000)
        }

        document.body.addEventListener('gesture-right', prev);
        document.body.addEventListener('gesture-left', next);

        const fetch = async () => {
            if (drink.attributes.img) {
                const imgFile = await Files.blob(drink.attributes.img.data.attributes.url);

                // setImg(imgBlob);
                window.document.getElementById('drink_details').style.backgroundImage = `url(${imgFile})`;
            }
        };

        fetch();

        return () => {
            window.removeEventListener('load', resetTimer);

            events.forEach(function (name) {
                document.removeEventListener(name, resetTimer);
            });

            document.body.removeEventListener('gesture-right', prev);
            document.body.removeEventListener('gesture-left', next);
        }
    }, [drink]);

    return (
        <div id={"drink_details"}>
            <div id={"drink_details_name"}>{drink.attributes.name}</div>
            <div id={"drink_details_info"}>{drink.attributes.description}</div>
            <div id={"drink_details_close"}>{close ? <Icon size={"huge"} onClick={close} name='close'/> : <></>}</div>
            <div id={"drink_details_prev"}>{prev ?
                <Icon size={"huge"} onClick={prev} name='angle double left'/> : <></>}</div>
            <div id={"drink_details_next"}>{next ?
                <Icon size={"huge"} onClick={next} name='angle double right'/> : <></>}</div>
        </div>
    )
}

export default Drink
